import React, {Component} from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import { Link } from 'react-router-dom'


class HymnIndex extends Component { 
    render() {
        return (
            <div>
                <h2>Index</h2>
                <ListGroup> 
                    {this.props.hymns_index.map((hymn, i) =>
                        <Link key={"index" + i} to={"/hymn/" + hymn.id} dangerouslySetInnerHTML={{__html: hymn.line + " " + hymn.id}} className="list-group-item" />
                    )}
                </ListGroup>
            </div>
        )
    }
}

export default HymnIndex;
