import React from 'react';
import HymnForm from './HymnForm';
import Update from './Update';

export default function Home() {
  return (
    <div>
      <h1>Welcome to my Hymnal Project</h1>
      <span className="text-danger">
        <strong>
          * Do not share this link with anybody!!
                          </strong>
      </span>
      <br />
      <br />
      <HymnForm />
      <br />
      <br />
      <Update homepage={true} />
    </div>
  )
}
