import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop'
import Hymn from './components/Hymn';
import HymnHome from './components/HymnHome';
import Header from './layout/Header';
import Home from './components/Home';
import HymnIndex from './components/HymnIndex';
import Search from './components/Search';
import Update from './components/Update';
import elasticlunr from 'elasticlunr';

class App extends Component { 
  state = {
    hymns: [],
    hymns_index: [],
    search_index: {}
  }

  // Load Hymn Content of a given  id (eg /hymn/12 loads hymn 12)
  HymnContent = ({ match }) => { 
    // Match Hymn # from URL
    var myHymn = this.state.hymns.filter(function (hymn) {
      return hymn.id === match.params.id;
    });

    // Calculate Previous and Next Hymn # based on current hymn 
    var cur_id = match.params.id;
    var prev = '';
    if (parseInt(cur_id) === 1) {
      prev = '';
    }
    else {
      prev = parseInt(cur_id) - 1;
    }
    var next = '';
    if (parseInt(cur_id) >= this.state.hymns.length) {
      next = '';
    }
    else {
      next = parseInt(cur_id) + 1;
    }
    return (
      <Hymn hymn={myHymn[0]} next={next} prev={prev} />
    )
  }

  // Load Hymn Index component and pass in the hymns_index state
  getHymnIndex = () => {
    return (
      <HymnIndex hymns_index={this.state.hymns_index} />
    )
  }

  // Load Search page and pass in search index data
  getSearchPage = () => {
    return (
      <Search search_index={this.state.search_index} />
    )
  }

  componentDidMount() { 
    // Configuration for text search
    var search_index = elasticlunr(function () {
      this.addField('first_line');
      this.addField('chorus_line');
      this.addField('content');
      this.setRef('id');
    });

    // Load main Hymns Data
    axios.get('/data/Hymns.json')
      .then(response => {
        this.setState({ hymns: response.data });
        response.data.forEach(function (hymn) {
          search_index.addDoc(hymn);
        });
        this.setState({ search_index: search_index });
      });

    // Load Index Page Data
    axios.get('/data/Hymns_Index.json')
      .then(response => {
        this.setState({ hymns_index: response.data });
      });
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <ScrollToTop>
            <Header />
            <Container>
              <Row>
                <Col sm={12}>
                  <br />
                  <Route exact path="/" render={Home} />
                  <Route exact path="/hymn" render={HymnHome} />
                  <Route path="/hymn/:id" render={this.HymnContent} />
                  <Route path="/index" render={this.getHymnIndex} />
                  <Route path="/search" render={this.getSearchPage} />
                  <Route path="/updates" render={Update} />
                </Col>
              </Row>
            </Container>
          </ScrollToTop>
        </Router>
      </React.Fragment>
    );
  }
}

export default App; 