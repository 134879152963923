import React from 'react'
import HymnForm from './HymnForm'

export default function HymnHome() {
  return (
    <div> 
      <HymnForm />
    </div>
  )
}
