import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'
import SearchResults from './SearchResults'

class Search extends Component {

    state = {
        query: '',
        results: []
    } 

     onChange = event => {
         var results = [];

        if(event.target.value.length > 2) {
            this.setState({ [event.target.id]: event.target.value }); 
            results = this.props.search_index.search(event.target.value, {
                fields: {
                    first_line: {boost: 2},
                    chorus_line: {boost: 2},
                    content: {boost: 1}
                }

            }); 
        } 
         this.setState({ results: results });
    }

    render() { 

        return (
            <React.Fragment> 
                <Form>
                    <Form.Group controlId="query">
                        <Form.Label><strong>Search</strong></Form.Label>
                        <Form.Control type="text" name="query" placeholder="Enter Search"  onChange={this.onChange}  />
                    </Form.Group>
                </Form> 

            <ListGroup> 
                <SearchResults search_index={this.props.search_index} results={this.state.results}  /> 
            </ListGroup>
            </React.Fragment>
        )
    }
}


export default Search;
