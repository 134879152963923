import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Swipeable, defineSwipe } from 'react-touch'
import { withRouter } from 'react-router-dom'


const swipe = defineSwipe({ swipeDistance: 150 });

class Hymn extends Component {

    gotoNextHymn = () => { 
        let { history, next } = this.props;
        history.push('/hymn/' + next);
    }
    gotoPrevHymn = () => { 
        let { history, prev } = this.props;
        history.push('/hymn/' + prev); 
    }

    hymn_content = () => { 
        if(this.props.hymn !==  undefined) {
            return {__html: this.props.hymn.content}
        }
        else {
            return {__html: 'Loading hymn' }
        }
    }

    render() {
        return (
            <Swipeable config={swipe} onSwipeLeft={() => this.gotoNextHymn()} onSwipeRight={() => this.gotoPrevHymn()} > 
            <Row> 
                <Col sm={12} dangerouslySetInnerHTML={this.hymn_content()}>
                </Col>
            </Row>
            </Swipeable>
        )
    }
}

export default withRouter(Hymn);
