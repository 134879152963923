import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

class HymnForm extends Component {
    state = {
        hymn_number: ''
    }

    onChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    goToHymn = () => {
        let { history } = this.props;
        history.push('/hymn/' + this.state.hymn_number);
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.goToHymn();
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="hymn_number">
                    <Form.Label><strong>Hymn Number</strong></Form.Label>
                    <Form.Control type="number" name="hymn_number" onChange={this.onChange} />
                </Form.Group>
                <Button variant="info" onClick={() => this.goToHymn()}>Go To Hymn</Button>
            </Form>
        )
    }
}

export default withRouter(HymnForm);