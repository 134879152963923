import React from 'react'
import { Container, Row, Col, Nav} from 'react-bootstrap'
import { NavLink, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faList, faSearch } from '@fortawesome/free-solid-svg-icons'




function Header() {
    return (
        <React.Fragment>
            <Container fluid>
                <Row className="bg-info">
                    <Col>
                        <h2 style={{ float: 'left', color: '#FFF' }}> <Link to="/" className="logo"> Hymnal </Link> </h2>
                        <Nav className="justify-content-end main-nav">
                            <NavLink to="/hymn" className="nav-link" activeClassName="active"><FontAwesomeIcon icon={faBook} size="lg" /></NavLink>
                            <NavLink to="/index" className="nav-link" activeClassName="active"><FontAwesomeIcon icon={faList} size="lg" /></NavLink>
                            <NavLink to="/search" className="nav-link" activeClassName="active"><FontAwesomeIcon icon={faSearch} size="lg" /></NavLink>
                        </Nav>
                    </Col>
                </Row>

            </Container>

        </React.Fragment>
    )
}


export default Header;