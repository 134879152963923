import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'

function Update(props) {
    return (
        <div> 
            <h3>News / Updates</h3> 
            <div className={(props.homepage ? 'd-none' : '')}>
                <Link to="/">Go Back</Link>
                <br />
            </div> 
            <br /> 

            <Row>
                <Col> <strong>04.20.2019</strong> </Col>
            </Row>
            <Row>
                <Col>
                    <u>Update(s)</u>
                    <ul>
                        <li> Added swipe to go to previous and next hymn</li>
                        <li> Converted App into a PWA (Progressive Web App), which means you can use this App offline.  To do so, add this page to your home screen.  <a href="https://www.howtogeek.com/196087/how-to-add-websites-to-the-home-screen-on-any-smartphone-or-tablet/" target="_blank" rel="noopener noreferrer">Click Here</a> if you don't know how. </li>
                    </ul>
                </Col>
            </Row>

            <Row className={(props.homepage ? '' : 'd-none')}>
                <Col>
                    <Link to="/updates">View more updates</Link>
                </Col>
            </Row> 
            <div className={(props.homepage ? 'd-none' : '')}>

                <Row>
                    <Col>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col> <strong>04.19.2019</strong> </Col>
                </Row>
                <Row>
                    <Col>
                        <u>Update(s)</u>
                        <ul>
                            <li> Added Search</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <u>Next Task</u>
                        <ul>
                            <li> Clean up some interface</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <u>Ongoing</u>
                        <ul>
                            <li> Improve search function</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col><hr /></Col>
                </Row>
                <Row>
                    <Col> <strong>04.18.2019</strong> </Col>
                </Row>
                <Row>
                    <Col>
                        <u>Update(s)</u>
                        <ul>
                            <li> Form for viewing a hymn </li>
                            <li> Index all hymns</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <u>Next Feature(s)</u>
                        <ul>
                            <li> Search function </li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <u>Future Features</u>
                        <ul>
                            <li> Subject of every hymn</li>
                            <li> Song information (Music Key, Author)</li>
                            <li> Guitar Chords </li>
                        </ul>
                    </Col>
                </Row> 
            </div> 
        </div>
    )
}


export default Update;