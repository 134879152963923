import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class SearchResults extends Component {


    getLink = (ref_num) => { 

        var result =  this.props.search_index.documentStore.getDoc(ref_num);
        var line = result.first_line + " " + result.id;
        return <Link to={"/hymn/" + result.id} dangerouslySetInnerHTML={{__html:line}} className="list-group-item" />
    }


  render() {



      return this.props.results.slice(0,50).map((row, i) => ( 
          <div key={i}>
            {this.getLink(row.ref)}
          </div>
      ));
  }
}


export default SearchResults;